.App {
  text-align: center;
  background-color: #232424;
}

@font-face {
  font-family: 'Rubik';
  src: url("./fonts/Rubik80sFade-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Dosis';
  src: url("./fonts/Dosis-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'DosisBold';
  src: url("./fonts/Dosis-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'TiltWarp';
  src: url("./fonts/TiltWarp-Regular.ttf") format("truetype");
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.main-container {
  background-color: "#232424";
  align-items: center;
  justify-content: center;
}