

.nav-button:hover {
    color:aliceblue;
}

.links:hover {
    cursor: pointer;
    box-shadow: inset;
    opacity: 100%;
}

.input:hover {
    cursor: pointer;
}

